<template>
  <EuiContainer>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiHeading :level="1" bold>{{ $t('signatories.users.index.title') }}</EuiHeading>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('signatories.users.index.block.title')">
              <EuiGrid>
                <EuiGridRow>
                  <EuiGridColumn>
                    <span v-if="selectedSignatories.length > 0" class="font-bold">{{ selectedSignatories.length }} Utilisateurs</span>
                  </EuiGridColumn>
                  <EuiGridColumn width="1/3">
                    <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                              v-model.trim="searchInput"
                              @keyup.enter="searchByName()">
                      <template #endEnhancer>
                        <div class="eui-m-cardFilter__clear">
                          <template v-if="searchInput && searchInput.length > 0">
                            <EuiButton iconOnly size="small" color="primary" @click.stop="onClear">
                              <EuiIcon name="close"/>
                            </EuiButton>
                            <div class="eui-m-cardFilter__separator"></div>
                          </template>
                        </div>
                        <EuiButton iconOnly color="primary" size="small" @click.stop="searchByName()">
                          <EuiIcon name="search"/>
                        </EuiButton>
                      </template>
                    </EuiInput>
                  </EuiGridColumn>
                </EuiGridRow>
              </EuiGrid>
              <EuiAdvancedTable v-if="signatories.length > 0">
                <template #tableHead>
                  <EuiTableHead @onSortClick="onSortClick($event, 'fullname')">
                    <div class="eui-u-flex">{{ $t('signatories.users.index.table.name') }}</div>
                  </EuiTableHead>
                  <EuiTableHead @onSortClick="onSortClick($event, 'email')">
                    <div class="eui-u-flex">{{ $t('signatories.users.index.table.email') }}</div>
                  </EuiTableHead>
                  <EuiTableHead>
                    <div class="eui-u-flex">{{ $t('signatories.users.index.table.group') }}</div>
                  </EuiTableHead>
                </template>
                <!-- table row -->
                <EuiTableRow v-for="item in signatories" :key="item.id" >
                  <!-- full name -->
                  <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                  <EuiTableCell>{{ item.email }}</EuiTableCell>
                  <EuiTableCell>{{ item.group }}</EuiTableCell>
                </EuiTableRow>
              </EuiAdvancedTable>
              <EuiPagination v-if="signatories.length"
                             class="mt-4"
                             :value="pagination.current"
                             @change="goToPage"
                             :paginationText="$t('pagination.text')"
                             :totalPages="pagination.total"/>
            <div v-if="signatories.length === 0">
              <p>{{ $t('signatories.users.index.noSignatories') }}</p>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SignatoriesUsersIndex',
  data() {
    return {
      signatories: [],
      selectedSignatories: [],
      searchInput: '',
      searchParams: {
        orders: [{ fieldName: 'fullname', orderBy: 'ASC' }],
        params: [],
        offset: 0,
        limit: 20,
      },
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.settingsUsers.pagination,
    }),
    selectAll: {
      get() {
        return this.signatories ? this.selectedSignatories.length === this.signatories.length : false;
      },
      set(value) {
        const selectedSignatories = [];

        if (value) {
          this.signatories.forEach((signatory) => {
            selectedSignatories.push(signatory);
          });
        }
        this.selectedSignatories = selectedSignatories;
      },
    },
  },
  methods: {
    ...mapActions({
      getInternalSignatories: 'ModuleEdocSign/settingsUsers/search',
      getInternalUserGroups: 'ModuleEdocSign/settingsGroups/search',
    }),
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.searchSignatories();
    },
    onClear() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.searchInput = '';
      this.searchSignatories();
    },
    searchByName() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [{
        paramName: 'token', paramValue: this.searchInput,
      }];
      this.searchSignatories();
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      const users = this.getInternalSignatories(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.getInternalUserGroups({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 1000 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      return Promise.all([users, groups]).then((values) => {
        for (let i = 0; i < values[0].length; i += 1) {
          const group = values[1].find((element) => element.id === values[0][i].userGroupId);
          if (group) {
            values[0][i].group = group.name;
          } else {
            // values[0][i].group = '';
          }
        }
        this.signatories = values[0];
      });
    },
    searchSignatories() {
      this.searchParams.offset = 0;
      const users = this.getInternalSignatories(this.searchParams).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.getInternalUserGroups({ orders: [{ fieldName: 'name', orderBy: 'DESC' }], offset: 0, limit: 20 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      return Promise.all([users, groups]).then((values) => {
        for (let i = 0; i < values[0].length; i += 1) {
          const group = values[1].find((element) => element.id === values[0][i].userGroupId);
          if (group) {
            values[0][i].group = group.name;
          } else {
            // values[0][i].group = '';
          }
        }
        this.signatories = values[0];
      });
    },
  },
  mounted() {
    this.searchSignatories();
  },
};
</script>
